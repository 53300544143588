"use client";
import lottie from "public/static/lottie.json";
import Lottie from "lottie-react";
import { Stack } from "@/components/ui/layout/stack";

export default function Loading() {
  return (
    <Stack className="h-screen w-screen" justify="center" align="center">
      <Lottie animationData={lottie} loop={true} className="max-w-[500px]" />
    </Stack>
  );
}
